import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import {
    Box,
    CircularProgress,
    Button,
    Tooltip,
    IconButton,
    Typography,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
} from "@mui/material"
import { CheckCircleOutline, ContentCopyOutlined } from "@mui/icons-material"
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table"
import { useMutation } from "@tanstack/react-query"
import { useSnackbar } from "notistack"

import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import AuthContext from "../../context/authProvider"

function ApiKeys() {
    const { secret, setSecret, merchantId } = useContext(AuthContext) as any
    const [copy, setCopy] = useState({ status: false, code: undefined })
    const [open, setOpen] = useState(false)

    // Custom Hook
    const axiosPrivate = useAxiosPrivate()

    // Snackbar
    const { enqueueSnackbar } = useSnackbar()

    // MUTATION
    const { isLoading, mutate } = useMutation(
        () => axiosPrivate.post(`/merchants/${merchantId}/generate_key`, {}),
        {
            onSuccess: (data) => {
                setSecret({
                    secret_token: data?.data?.data?.secret_token,
                    web_secret: data?.data?.data?.secret,
                })
                enqueueSnackbar("Successesfully generated.", {
                    variant: "success",
                })
                setOpen(false)
            },
            onError: (error: any) => {
                const fieldError =
                    error?.response?.data?.error?.field_error[0]?.description
                const errorMessage = error?.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError || errorMessage || "Request Failed",
                    { variant: "error" }
                )
            },
        }
    )

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleConfirm = () => {
        mutate()
    }

    const copySecret = useCallback(
        ({ cell }: any) => (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {
                        xs: "column",
                        sm: "row",
                    },
                    gap: "1em",
                    alignItems: "center",
                }}
                key={cell.getValue()}
            >
                <Box sx={{ justifyContent: "start" }}>
                    <Typography
                        textAlign="center"
                        fontWeight="bold"
                        variant="h5"
                    >
                        *****
                    </Typography>
                </Box>
                {copy.status && copy.code === cell.getValue() ? (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.1em",
                            color: "#57d246",
                        }}
                    >
                        <Typography
                            textAlign="center"
                            fontWeight="bold"
                            variant="body2"
                        >
                            Copied!
                        </Typography>
                        <CheckCircleOutline sx={{ alignSelf: "center" }} />
                    </Box>
                ) : (
                    <Box>
                        <Tooltip
                            title={
                                cell.getValue() === ""
                                    ? "Please generate to copy"
                                    : "Click to copy"
                            }
                        >
                            <IconButton
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        cell.getValue()
                                    )
                                    setCopy({
                                        status: true,
                                        code: cell.getValue(),
                                    })
                                }}
                                disabled={cell.getValue() === ""}
                            >
                                <ContentCopyOutlined
                                    sx={{ color: "primary.main" }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            </Box>
        ),
        [copy]
    )
    const columns = useMemo<MRT_ColumnDef<object>[]>(
        () => [
            {
                size: 300,
                accessorKey: "secret_token",
                header: "Secret Token",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                Cell: copySecret,
            },
            {
                size: 300,
                accessorKey: "web_secret",
                header: "Web Secret",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                Cell: copySecret,
            },
        ],
        [copySecret]
    )

    useEffect(() => {
        if (copy.status) {
            setTimeout(() => setCopy({ status: false, code: undefined }), 2000)
        }
    }, [copy.status])
    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                }}
            >
                <Tooltip title="Generate Api Key">
                    <Button
                        size="small"
                        variant="contained"
                        onClick={handleClickOpen}
                        disabled={isLoading}
                        sx={{
                            bgcolor: "primary.main",
                            color: "secondary.main",
                            textTransform: "none",
                            px: 2,
                            py: 1,
                            my: 1,
                            boxShadow: 2,
                            " :hover": {
                                bgcolor: "primary.main",
                                color: "secondary.main",
                            },
                        }}
                    >
                        Generate API Key
                        {isLoading && (
                            <CircularProgress
                                size="1em"
                                sx={{ p: "0px", m: "0px" }}
                            />
                        )}
                    </Button>
                </Tooltip>
            </Box>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#fafafa",
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 20,
                                fontWeight: "bold",
                                textAlign: "center",
                            }}
                        >
                            {" "}
                            Confirm Action
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" mt={2} mx={1}>
                        <Typography>
                        Are you sure you want to proceed with generating the
                        key? <br/> This action will change the behavior of the whole 
                        <strong> API</strong> requests.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        mt: 1,
                        pb: 2,
                        pr: 3,
                        pt: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        bgcolor: "#fafafa",
                    }}
                >
                    <Button 
                        size="small"
                        variant="contained"
                        sx={{
                            ":hover": { bgcolor: "#e4e4e4" },
                            backgroundColor: "#e4e4e4",
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }} onClick={() => setOpen(false)} >
                            <Typography color="black">Cancel</Typography>
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                            bgcolor: "primary.main",
                            ":hover": {
                                bgcolor: "primary.main",
                            },
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }} onClick={handleConfirm}  autoFocus>
                        <Typography sx={{ color: "secondary.main" }}>Confirm</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
            <Box
                sx={{
                    backgroundColor: "white",
                }}
            >
                <MaterialReactTable
                    data={[
                        {
                            secret_token: secret?.secret_token ?? "",
                            web_secret: secret?.web_secret ?? "",
                        },
                    ]}
                    columns={columns}
                    enableTopToolbar={false}
                    enableBottomToolbar={false}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enablePagination={false}
                    enableSorting={false}
                    state={{
                        isLoading: isLoading,
                    }}
                />
            </Box>
        </Box>
    )
}

export default ApiKeys
