import { useContext, useState } from "react"
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material"
import { useSnackbar } from "notistack"
import { Controller, useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"

import AuthProvider from "../../context/authProvider"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import useFetchEntities from "../../hooks/useFetchEntities"

interface MethodFormProps {
    method_id: string | undefined
}

interface GenerateMethodSecretProps {
    open: boolean
    setOpen: (arg: boolean) => void
}

function GenerateMethodSecretForm(props: GenerateMethodSecretProps) {
    const { open, setOpen } = props
    const { merchantId, setSecret } = useContext(AuthProvider) as any
    const [methodId, setMethodId] = useState<string | undefined>()
    const [openDialog, setOpenDialog] = useState<boolean>(false)

    // HOOK FORM
    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm<MethodFormProps>({ mode: "onChange" })

    const { enqueueSnackbar } = useSnackbar()

    const axiosPrivate = useAxiosPrivate()

    const { isLoading: methodsDataLoading, data: methods } = useFetchEntities({
        endPoint: "/payment-methods",
    }) as any
    const { mutate, isLoading } = useMutation(
        () =>
            axiosPrivate.post(
                `/merchants/${merchantId}/payment-methods/${methodId}/generate_key`
            ),
        {
            onSuccess: (data: any) => {
                enqueueSnackbar("Generated successfully", {
                    variant: "success",
                })
                setSecret({
                    secret_key: data?.data?.data?.secret_key,
                    client_id: data?.data?.data?.client_id,
                })
                setOpen(false)
                setOpenDialog(false)
            },
            onError: (error: any) => {
                const fieldError =
                    error.response?.data?.error?.field_error?.[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError || errorMessage || "Request Failed",
                    {
                        variant: "error",
                    }
                )
            },
        }
    ) as any

    const onSubmit = (inputData: MethodFormProps) => {
        setMethodId(inputData.method_id)
        mutate()
    }
    const handleClickOpenDialog = () => {
        if (getValues("method_id")) {
            setOpenDialog(true);
        } else {
            enqueueSnackbar("Please select a payment method.", { variant: "error" });
        }
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    if (openDialog) {
        return (
            <Dialog
                fullWidth
                maxWidth="sm"
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#fafafa",
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 20,
                                fontWeight: "bold",
                                textAlign: "center",
                            }}
                        >
                            {" "}
                            Confirm Action
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" mt={2}>
                        <Typography>
                            Are you sure you want to proceed with generating the
                            method secret?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        mt: 1,
                        pb: 2,
                        pr: 3,
                        pt: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        bgcolor: "#fafafa",
                    }}
                >
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                            ":hover": { bgcolor: "#e4e4e4" },
                            backgroundColor: "#e4e4e4",
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }}
                        onClick={handleCloseDialog}
                    >
                        <Typography color="black">Back</Typography>
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                            bgcolor: "primary.main",
                            ":hover": {
                                bgcolor: "primary.main",
                            },
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }}
                        onClick={handleSubmit(onSubmit)}
                        autoFocus
                        type="submit"
                    >
                        <Typography sx={{ color: "secondary.main" }}>
                            Confirm
                        </Typography>
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "black",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
    return methodsDataLoading ? (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <CircularProgress
                size={30}
                sx={{
                    color: "black",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                }}
            />
        </Box>
    ) : (
        <Dialog
            maxWidth="sm"
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        {" "}
                        Request Payment
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        mx: 1,
                        mt: 1,
                    }}
                >
                    <Controller
                        name="method_id"
                        control={control}
                        rules={{
                            required: "Please select a payment method.",
                        }}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="Payment Method"
                                margin="normal"
                                fullWidth
                                select
                                error={!!errors?.method_id}
                                helperText={
                                    errors?.method_id
                                        ? errors.method_id?.message
                                        : null
                                }
                                size="small"
                                {...field}
                            >
                                {methods?.data?.map(
                                    (item: {
                                        id: string | undefined
                                        name: string | undefined
                                        logo: string | undefined
                                    }) => (
                                        <MenuItem
                                            value={item?.id}
                                            key={item?.id}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Avatar
                                                    sx={{
                                                        width: 30,
                                                        height: 30,
                                                        mr: 1,
                                                    }}
                                                    src={item?.logo}
                                                />
                                                {item.name}
                                            </Box>
                                        </MenuItem>
                                    )
                                )}
                            </TextField>
                        )}
                    />
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    mt: 1,
                    pb: 2,
                    pr: 3,
                    pt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={() => setOpen(false)}
                >
                    <Typography color="black">Cancel</Typography>
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        bgcolor: "primary.main",
                        ":hover": {
                            bgcolor: "primary.main",
                        },
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={handleClickOpenDialog}
                    disabled={isLoading}
                >
                    <Typography sx={{ color: "secondary.main" }}>
                        Next
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default GenerateMethodSecretForm
