import { Box, Grid } from "@mui/material"
import ApiKeys from "./apiKeys"
import PaymentCredentials from "./paymentCredentials "

export default function Credentials() {
    return (
        <Box sx={{mt:1}}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <ApiKeys />
                </Grid>
                <Grid item xs={6}>
                    <PaymentCredentials />
                </Grid>
            </Grid>
        </Box>
    )
}
