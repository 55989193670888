import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import {
    Box,
    Button,
    Tooltip,
    IconButton,
    Typography,
} from "@mui/material"
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table"
import { CheckCircleOutline, ContentCopyOutlined } from "@mui/icons-material"

import GenerateMethodSecretForm from "./methodForm"
import AuthContext from "../../context/authProvider"

function PaymentCredentials() {
    const {secret} = useContext(AuthContext) as any
    const [open, setOpen] = useState<boolean>(false)
    const [copy, setCopy] = useState({ status: false, code: undefined })

    // const 
    const copySecret = useCallback(
        ({ cell }: any) => (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {
                        xs: "column",
                        sm: "row",
                    },
                    gap: "1em",
                    alignItems: "center",
                }}
                key={cell.getValue()}
            >
                <Box sx={{ justifyContent: "start" }}>
                    <Typography
                        textAlign="center"
                        fontWeight="bold"
                        variant="h5"
                    >
                        *****
                    </Typography>
                </Box>
                {copy.status && copy.code === cell.getValue() ? (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.1em",
                            color: "#57d246",
                        }}
                    >
                        <Typography
                            textAlign="center"
                            fontWeight="bold"
                            variant="body2"
                        >
                            Copied!
                        </Typography>
                        <CheckCircleOutline sx={{ alignSelf: "center" }} />
                    </Box>
                ) : (
                    <Box>
                        <Tooltip title={cell.getValue() === "" ?"Please generate": "Click to copy"}>
                            <IconButton
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        cell.getValue()
                                    )
                                    setCopy({
                                        status: true,
                                        code: cell.getValue(),
                                    })
                                }}
                                disabled={cell.getValue() === ""}
                            >
                                <ContentCopyOutlined
                                    sx={{ color: "primary.main" }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            </Box>
        ),
        [copy]
    )
    const columns = useMemo<MRT_ColumnDef<object>[]>(
        () => [
            {
                size: 300,
                accessorKey: "secret_key",
                header: "Secret Key",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                Cell: copySecret,
            },
            {
                size: 300,
                accessorKey: "client_id",
                header: "Client ID",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                Cell: copySecret,
            },
        ],
        [copySecret]
    )

    useEffect(() => {
        if (copy.status) {
            setTimeout(() => setCopy({ status: false, code: undefined }), 2000)
        }
    }, [copy.status])
    return (
        <Box width="100%">
            {open && <GenerateMethodSecretForm open={open} setOpen={setOpen}/>}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                }}
            >
                <Tooltip title="Generate Payment Method Secret">
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => setOpen(true)}
                        disabled={open}
                        sx={{
                            bgcolor: "primary.main",
                            color: "secondary.main",
                            textTransform: "none",
                            px: 2,
                            py: 1,
                            my:1,
                            boxShadow: 2,
                            " :hover": {
                                bgcolor: "primary.main",
                                color: "secondary.main",
                            },
                        }}
                    >
                        Generate Payment Method Secret
                    </Button>
                </Tooltip>
            </Box>
            <Box
                sx={{
                    backgroundColor: "white",
                }}
            >
                <MaterialReactTable
                    data={[
                        {
                            secret_key: secret?.secret_key ?? "",
                            client_id: secret?.client_id ?? ""
                        },
                    ]}
                    columns={columns}
                    enableTopToolbar={false}
                    enableBottomToolbar={false}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enablePagination={false}
                    enableSorting={false}
                />
            </Box>
        </Box>
    )
}

export default PaymentCredentials
